<template>
  <div class="approval-detail-view">
    <section class="detail-comment">
      <div class="inner-box">
        <div class="comment-left">
          <user-card
            v-if="type !== 'channel'"
            :obj="{
              avatar: initiateObj.avatar,
              name: initiateObj.promoterName,
              job: initiateObj.promoterOrgChain,
              userId: initiateObj.promoterId
            }"
          >
            <template #reward>
              <span class="reward" v-if="initiateObj.processState === 6"
                >奖励{{ initiateObj.finalScore || 0 }}分</span
              >
            </template>
            <template #status>
              <div class="status-box">
                <span class="num">NO.{{ initiateObj.customCode || '' }}</span>
                <span class="status" :class="`status-${initiateObj.processState}`">{{
                  initiateObj.processStateName
                    | formateProcessState(initiateObj.approverName, initiateObj.processState)
                }}</span>
              </div>
            </template>
          </user-card>
          <div class="list-code" v-else>
            <span class="num">NO.{{ initiateObj.customCode || '' }}</span>
          </div>
          <div class="form-box">
            <div class="item" v-for="(item, index) in formList" :key="index">
              <div class="title">{{ item.title }}</div>
              <p class="value">{{ item.value }}</p>
            </div>
          </div>
          <div class="form-box" v-for="(it, indexs) in itemData" :key="indexs">
            <div class="item" v-for="(item, index) in it" :key="index">
              <div class="title">{{ item.title }}</div>
              <p class="value">{{ item.value }}</p>
            </div>
          </div>
        </div>
        <div class="comment-right" v-if="type !== 'channel'">
          <div class="title">
            流程记录
          </div>
          <record-list :list="activities" :score="score" />
        </div>
      </div>
    </section>
    <div
      class="result-bottom"
      v-if="type === 'initiate' || (type === 'approval' && initiateObj.isOpen)"
    >
      <!-- 审批中4-xx -->
      <el-button
        class="blue"
        type="primary"
        @click="handleMessageBox('revoke')"
        v-if="type === 'initiate' && [3, 4].includes(initiateObj.processState)"
        >撤回</el-button
      >
      <!-- 已撤回 2 已驳回 5-xx 审批通过6-xx -->
      <el-button
        class="white"
        @click="handleMessageBox('delete')"
        v-if="type === 'initiate' && [2, 5, 6].indexOf(initiateObj.processState) > -1"
        >删除</el-button
      >
      <!-- 已撤回2 已驳回5-xx -->
      <el-button
        type="primary"
        class="blue"
        @click="handleEdit"
        v-if="type === 'initiate' && [2, 5].indexOf(initiateObj.processState) > -1"
        >编辑</el-button
      >
      <el-button
        class="white"
        @click="handleDialog()"
        v-if="type === 'approval' && initiateObj.isOpen && initiateObj.isEntrust"
        >委托他人</el-button
      >
      <el-button
        class="white red"
        @click="handleDialog(5)"
        v-if="type === 'approval' && initiateObj.isOpen"
        >驳回</el-button
      >
      <el-button
        type="primary"
        class="blue"
        @click="handleDialog(4)"
        v-if="type === 'approval' && initiateObj.isOpen"
        >通过</el-button
      >
    </div>
    <!-- 审批说明弹框 -->
    <approvalExplanation :showDialog="showDialog" modelTyle="team" />
    <!-- 通过 -->
    <pass :showDialog="showPassDialog" :passObj="passObj" :moduleName="moduleName" />
    <!-- 委托他人 -->
    <entrust :showDialog="showEntrustDialog" :entrustObj="entrustObj" :moduleName="moduleName" />
    <!-- 驳回 -->
    <rejected :showDialog="showRejectedDialog" :rejectObj="rejectObj" :moduleName="moduleName" />
  </div>
</template>

<script>
import userCard from '@/components/initiate/user-card'
import approvalExplanation from '@/components/initiate/approval-explanation'
import {
  getCustomerDetail,
  getCustomerDetailProcess,
  postProcessRevoke,
  postCustomerDelete
} from '@/api/api_customer'
import pass from '@/components/initiate/pass-dialog'
import rejected from '@/components/initiate/rejected-dialog'
import entrust from '@/components/initiate/entrust-dialog'
import recordList from '@/components/initiate/record-list'
import { MessageBox, Message } from 'element-ui'
export default {
  name: 'approval-detail',
  components: {
    userCard,
    approvalExplanation,
    pass,
    rejected,
    entrust,
    recordList
  },
  props: {
    id: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'initiate' //initiate 我发起的 approval 我审批的 channel 从首页进来的
    },
    modelCode: {
      type: String,
      default: 'innovate' //innovate 创新成果 team 团队贡献
    },
    moduleName: {
      type: String,
      default: 'all'
    }
  },
  data() {
    return {
      name: sessionStorage.getItem('name'),
      rejectObj: {}, //驳回参数
      passObj: {}, //通过参数
      entrustObj: {}, //委托他人
      score: '', //最后得分
      showDialog: false,
      showEntrustDialog: false, //委托他人
      showRejectedDialog: false, //驳回
      showPassDialog: false, //通过弹框
      initiateObj: {},
      userObj: {},
      fileObj: {
        title: '上传文件',
        code: null
      },
      fileList: [],
      activities: [],
      itemData: [],
      formList: []
    }
  },
  computed: {},
  beforeRouteEnter(to, from, next) {
    to.meta.title = to.query.name ?? sessionStorage.getItem('name') + '提案审批'
    next()
  },
  created() {
    this.handleDetail()
    this.handleprocessList()
  },
  filters: {
    formateProcessState(name, val, code) {
      //审批中-xx 已驳回-xx
      //提交 1 已撤销 2  待审批 3
      //审批中 4 驳回 5  审批通过 6
      //问题 ？？？待审批没对应颜
      if ([3, 4, 5].includes(code)) {
        return `${name}-${val}`
      } else {
        return `${name}`
      }
    }
  },
  methods: {
    /**
     * 详情
     */
    handleDetail() {
      let params = {
        customCode: this.id,
        dimensionCode: sessionStorage.getItem('code')
      }
      getCustomerDetail(params)
        .then(res => {
          if (res.data) {
            this.initiateObj = res.data
            let { webMatterOutputList } = res.data
            for (let i = 0; i < 2; i++) {
              let el = webMatterOutputList[i]
              let obj = {}
              this.$set(obj, 'title', el.matterName)
              this.$set(obj, 'value', el.matterResult)
              this.formList.push(obj)
            }
            for (let j = 2; j < webMatterOutputList.length; j++) {
              let item = webMatterOutputList[j]
              let { matterName, matterResult, remarks } = item
              let selObj = {
                matterName,
                matterResult,
                remarks
              }
              let formSelList = [
                {
                  title: '事项',
                  cname: 'matterName',
                  value: ''
                },
                {
                  title: '完成结果',
                  cname: 'matterResult',
                  value: ''
                },
                {
                  title: '备注',
                  cname: 'remarks',
                  value: ''
                }
              ]
              for (let key in selObj) {
                let f = formSelList.find(el => el.cname === key)
                f.value = selObj[key] ? selObj[key] : ''
              }
              this.itemData.push(formSelList)
            }

            // this.fileList = contributeAnnexList
          } else {
            this.initiateObj = {}
            if (res.code === '000015' || res.code === '000500') {
              setTimeout(() => {
                this.$router.push({
                  path:
                    sessionStorage.getItem('code') === 'customize1'
                      ? 'customer-channel'
                      : 'customer2-channel'
                })
              }, 700)
            }
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    /**
     * 流程记录
     */
    handleprocessList() {
      getCustomerDetailProcess({
        customCode: this.id,
        dimensionCode: sessionStorage.getItem('code')
      })
        .then(res => {
          let { customProcessOperateLogOutputList, finalScoreStr, finalScore } = res.data
          this.score = finalScoreStr
          this.activities = customProcessOperateLogOutputList
          this.activities.forEach(el => {
            //operationStateName 状态name
            // operationState	状态值,1:发起,2:已撤销,3:待审批,4:审批中,5:驳回,6:审批通过,7:委托,8:重新提交
            el.case = el.operationStateName
            switch (el.operationState) {
              case 1:
                el.case = ''
                break
              case 4:
              case 5:
              case 6:
                el.case = `${el.operationStateName}${el.remarks ? ',' : ''}${el.remarks || ''}`
                break
              case 7:
                //委托
                el.case = el.operationDesc
                break
            }
          })
        })
        .catch(err => {})
    },
    handleDialog(code) {
      //审批状态 5 驳回  4通过
      if (code === 5) {
        this.showRejectedDialog = true
        this.rejectObj = {
          processExampleId: this.initiateObj.processExampleId,
          innovateCode: this.id
        }
      } else if (code === 4) {
        this.showPassDialog = true
        this.passObj = {
          processExampleId: this.initiateObj.processExampleId,
          innovateCode: this.id,
          score: this.initiateObj?.finalScore ?? null
        }
      } else {
        //委托他人
        this.showEntrustDialog = true
        this.entrustObj = {
          processExampleId: this.initiateObj.processExampleId,
          innovateCode: this.id
        }
      }
    },
    /**
     * 撤回
     */
    handleRevoke() {
      postProcessRevoke({ customCode: this.id, dimensionCode: sessionStorage.getItem('code') })
        .then(res => {
          if (res.code === '000000') {
            this.$msgSuccess(res.msg || '操作成功')
            this.$router.push({
              name:
                sessionStorage.getItem('code') === 'customize1'
                  ? 'customer-initiate'
                  : 'customer2-initiate',
              query: { moduleName: this.moduleName }
            })
          } else {
            this.$msgError(res.msg || '操作失败')
          }
        })
        .catch(err => {
          console.log('err', err)
          this.$msgError('异常')
        })
    },
    /**
     * 删除
     */
    handleDelete() {
      postCustomerDelete({ customCode: this.id, dimensionCode: sessionStorage.getItem('code') })
        .then(res => {
          if (res.code === '000000') {
            this.$msgSuccess(res.msg || '操作成功')
            this.$router.push({
              name:
                sessionStorage.getItem('code') === 'customize1'
                  ? 'customer-initiate'
                  : 'customer2-initiate',
              query: { moduleName: this.moduleName }
            })
          } else {
            this.$msgError(res.msg || '操作失败')
          }
        })
        .catch(err => {
          console.log('err', err)
          this.$msgError('异常')
        })
    },
    /**
     * 撤回 删除确认框
     * type  invoke 撤回  delete 删除
     */
    handleMessageBox(type) {
      let text = type === 'delete' ? '删除' : '撤回'
      MessageBox.confirm(`确定${text}此${this.name}提案吗？`, '提示', {
        confirmButtonText: `确定${text}`,
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          if (type === 'revoke') {
            this.handleRevoke()
          } else {
            this.handleDelete()
          }
        })
        .catch(() => {
          Message.info(`已取消操作`)
        })
    },
    handleEdit() {
      let path =
        sessionStorage.getItem('code') === 'customize1'
          ? 'customer-initiateProposal'
          : 'customer2-initiateProposal'
      this.$router.push({
        name: path,
        query: {
          type: 'edit',
          id: this.id,
          moduleName: this.moduleName
        }
      })
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/css/initiate.scss';
</style>
